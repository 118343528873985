import React, { Component } from "react"
import Layout from "../components/Layout"
import { queryGraphql } from "../services/api"
import { buildBlocks } from "../services/blockGenerator"
import { PreviewBlocks } from "@/data/fragments"
import moment from "moment"

class Preview extends Component {
  state = {
    loading: true,
    title: "",
    blocks: [],
    meta: {},
  }

  constructor(props) {
    super(props)
    if (typeof window !== "undefined") {
      const parsedUrl = new URL(window.location.href)
      const id = parsedUrl.searchParams.get("id")
      const token = parsedUrl.searchParams.get("token")
      const endpoint = parsedUrl.searchParams.get("endpoint")

      const blocks = PreviewBlocks

      const queryObject = {
        query: `
                {
                    post(id: "${id}", idType: DATABASE_ID) {
                        title
                        date
                        categories {
                          nodes {
                            name
                            slug
                          }
                        }
                        Meta {
                            fieldGroupName
                            metaDescription
                            metaTitle
                            ogDescription
                            ogTitle
                            twitterCardType
                            twitterDescription
                            twitterTitle
                            twitterImage {
                                uri
                            }
                            ogImage{
                                uri
                            }
                        }
                        ... on BlockEditorContentNode {
                            blocks {
                                ... on CoreBlock {
                                  reusableBlock {
                                    __typename
                                    ... on ReusableBlock {
                                      id
                                      blocks {
                                        ${blocks}
                                        innerBlocks {
                                            name
                                            ${blocks}
                                            innerBlocks {
                                                name
                                                ${blocks}
                                                innerBlocks {
                                                    name
                                                    ${blocks}
                                                }
                                            }
                                        }
                                      }
                                    }
                                  }
                                }
                                name
                                ${blocks}
                                innerBlocks {
                                    name
                                    ${blocks}
                                    innerBlocks {
                                        name
                                        ${blocks}
                                        innerBlocks {
                                            name
                                            ${blocks}
                                        }
                                    }
                                }
                            }
                        }
                    },
                }
                `,
      }
      this.createContent(queryObject, token, endpoint)
    }
  }

  createContent = (queryObject, token, endpoint) => {
    queryGraphql(queryObject, token, endpoint, (response) => {
      const post = response.data.data.post
      if (post === null) {
        this.setState({
          error: true,
          errorMessage:
            "Unable to fetch post, try refreshing the admin panel page before clicking preview.",
        })
      } else {
        const pageContext = {
          cats: post.categories,
          date: moment(post.date).format("DD MMMM YYYY"),
          pageTitle: post.Meta.metaTitle ? post.Meta.metaTitle : post.title
        }
        const generatedBlocks = buildBlocks(post.blocks, {}, pageContext)
        this.setState({
          loading: false,
          blocks: generatedBlocks,
          title: pageContext.pageTitle,
          meta: post.Meta,
          themeOptions: response.data.data.themeOptions,
        })
      }
    })
  }

  render() {
    if (this.state.loading === true) {
      return (
        <Layout meta={{}} title="Preview">
          <h1>Preview</h1>
          <p>Loading...</p>
          {this.state.error === false ? (
            ""
          ) : (
            <p className={"error"}>{this.state.errorMessage}</p>
          )}
        </Layout>
      )
    } else {
      return (
        <Layout meta={this.state.meta} title={`Preview: ${this.state.title}`}>
          {this.state.blocks.map((block) => {
            return block
          })}
        </Layout>
      )
    }
  }
}

export default Preview
